import React, { Component } from "react"
import moment from "moment"
import "moment/locale/de";
import EventListItem from "./list-item"
import Empty from "../utils/empty"

class EventList extends Component {
    currentDate = null

    render() {
        const { events, type } = this.props
        const today = moment()

        if (!events.length) {
            return (
                <Empty>Wir konnten leider keine {type ? `Veranstaltungen in der Kategorie ${type.label}` : "Veranstaltungen"} finden.</Empty>
            )
        }

        return (
            <div>
                {events.map(({ date, node }, i) => {
                    let start = moment(date.from)
                    let headline;

                    if (start.isBefore())
                        start = moment()

                    if (start.isSame(today, "day"))
                        headline = "Heute"
                    else if (start.isSame(moment().add(1, "day"), "day"))
                        headline = "Morgen"
                    else
                        headline = start.format('LL')

                    if (this.currentDate && this.currentDate.isSame(start, "day")) {
                        headline = null
                    } else {
                        this.currentDate = start
                    }

                    return (
                        <React.Fragment key={`${node.id}-${i}`}>
                            {headline && <h2 className="mb-2 smaller mt-md">{headline}</h2>}
                            <EventListItem event={node} date={date} timeOnly={true} />
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }
}

export default EventList;
