import React, { Component } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Avatar from "../utils/avatar"
import Timing from "./timing"

class EventListItem extends Component {
    address() {
        const { name } = this.props.event.acf.location
        const location = []

        if (name) location.push(name)
        //if (address) location.push(address)

        return location.join(', ')
    }

    render() {
        const { event, date, timeOnly } = this.props
        const { featured_media } = event
        const address = this.address()
        const { cost, excerpt } = event.acf

        return (
            <Link to={`/events/${event.slug}`} className="flex block py-3 link list-item">
                <Avatar className="flex-none w-16 ml-0 mr-sm">
                    {featured_media && featured_media.localFile &&
                        <Img
                            fixed={featured_media.localFile.childImageSharp.fixed}
                            alt={event.title}
                            />
                    }
                </Avatar>
                <div className="py-2 pr-2">
                    <h3 className="mt-0 mb-1 text-base" dangerouslySetInnerHTML={{ __html: event.title }} />
                    {excerpt && <div className="mb-2 -mt-1 text-gray-500 ">{excerpt}</div>}
                    <Timing className="block mb-1 -mt-1 text-gray" event={event} date={date} timeOnly={timeOnly} />
                    <ul className="meta">
                        {address && <li>{address}</li>}
                        {cost && <li>{cost}</li>}
                    </ul>
                </div>
            </Link>
        )
    }
}

export default EventListItem
