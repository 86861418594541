import React, { Component } from "react"
import _ from "underscore"
import { StateContext } from "../components/common/provider"
import moment from "moment"
import "moment/locale/de";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale, setDefaultLocale } from  "react-datepicker"
import de from 'date-fns/locale/de'

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import { graphql } from "gatsby"
import EventList from "../components/events/list"
import Types from "../components/events/types"
import Search from "../components/utils/search"


class EventsPage extends Component {
    static contextType = StateContext

    state = {
        activeEventtype: null,
        searchResults: null,
        startDate: null
    }

    eventList = []
    searchIndex = []

    constructor(props) {
        super(props)

        this.handleDateChange = this.handleDateChange.bind(this)
        this.resetDate = this.resetDate.bind(this)

        this.datepickerRef = React.createRef()
    }

    componentDidMount() {
        const { state } = this.props.location
        
        if (state && state.activeEventtype) {

            this.context.setEventType(state.activeEventtype)

            this.setState({
                activeEventtype: state.activeEventtype
            })

        } else if (this.context.eventType) {
            this.setState({
                activeEventtype: this.context.eventType
            })
        }

        if (this.context.eventDateFilter) {
            this.setState({
                startDate: this.context.eventDateFilter
            })
        }

        registerLocale('de', de)
        setDefaultLocale('de');
    }

    handleDateChange(date) {
        this.context.setEventDateFilter(date)

        this.setState({
            startDate: date
        });
    }

    resetDate(e) {
        e.stopPropagation()

        this.context.setEventDateFilter(null)

        this.setState({
            startDate: null
        });
    }

    eventtypeSelected = (eventtype) => {
        const self = this
        
        this.context.setEventType(eventtype)

        this.setState({ activeEventtype: eventtype }, function() {
            self.search.updateIndex(this.searchIndex)
        })
    }

    handleSearch = (results) => {
        if (results) {
            results = _.pluck(results, 'ref')
        }

        this.setState({ searchResults: results })
    }

    render() {
        const self = this
        const { allWordpressWpEvents } = this.props.data

        const dateLabel = this.state.startDate
            ? moment(this.state.startDate).format('ll')
            : "Nach Datum filtern"

        self.searchIndex = []

        this.eventList = allWordpressWpEvents.edges
            .reduce((result, current) => {
                let items = []

                if (current.node.acf.date.multiple_dates) {
                    if (! Array.isArray(current.node.acf.date.dates) || ! current.node.acf.date.dates.length)
                        return result;
                    
                    current.node.acf.date.dates.forEach((date, i) => {
                        items.push({
                            ...current,
                            date: {
                                from: date.from,
                                to: date.to,
                                whole_day: current.node.acf.date.whole_day,
                            },
                            isCopy: i > 0,
                        })
                    })
                } else {
                    items.push({
                        ...current,
                        date: {
                            from: current.node.acf.date.from,
                            to: current.node.acf.date.to,
                            whole_day: current.node.acf.date.whole_day,
                        },
                        isCopy: false,
                    })
                }

                return result.concat(items)
            }, [])
            .sort((a, b) => {
                if (a.date.to > b.date.to)
                    return 1

                if (a.date.to < b.date.to)
                    return -1

                return 0
            })
            .filter(({ date, node, isCopy }) => {
                if (date.to) {
                    const start = moment(date.from)
                    const end = moment(date.to)

                    if (date.whole_day)
                        end.endOf("day")

                    if (!end.isAfter())
                        return false

                    if (self.state.startDate) {
                        const wantedDate = moment(self.state.startDate)

                        start.startOf("day")
                        end.endOf("day")

                        if (!wantedDate.isBetween(start, end, null, '[]')) {
                            return false
                        }
                    }

                } else {
                    return false
                }

                let isListed = true

                const { activeEventtype, searchResults } = self.state

                if (activeEventtype) {
                    isListed = node.eventtypes.includes(activeEventtype.key)
                }

                if (isListed) {
                    self.searchIndex.push({
                        id: node.id,
                        title: node.title,
                        content: node.content,
                        excerpt: node.acf.excerpt,
                        address: node.acf.address,
                        address_2: node.acf.address_2
                    })
                }

                if (isListed && searchResults) {
                    return searchResults.includes(node.id)
                }

                return isListed
            })

        return (
            <Layout mainClassNameAppend="pt-px">
                <SEO title="Veranstaltungen" />
                <h1 className="screen-reader-text">Veranstaltungen</h1>

                <div className="container narrow">
                    <Search placeholder="Veranstaltung suchen" index={this.searchIndex} handleSearch={this.handleSearch} onRef={ref => (this.search = ref)} />
                </div>
                <div className="container narrow">
                    <Types handleSelect={this.eventtypeSelected} activeTag={this.state.activeEventtype} disabledState={true}>

                        <li className="mx-1">
                            <button onClick={() => {this.datepickerRef.current.setOpen(true)}} className={["outline-none flex-none font-bold text-white rounded-md inline-block whitespace-no-wrap p-4", this.state.startDate ? "bg-primary" : "bg-gray-500"].join(" ")} title="Ergebnisse nach Datum filtern" aria-label="Ergebnisse nach Datum filtern">
                                {dateLabel}

                                {this.state.startDate &&
                                <span className="inline-block ml-2" onClick={(e) => this.resetDate(e)} onKeyUp={(e) => this.resetDate(e)} role="button" aria-label="Filter zurücksetzen" tabIndex={0}>
                                    <FontAwesomeIcon icon={["fal", "times"]} />
                                </span>
                                }
                            </button>
                        </li>

                    </Types>
                </div>
                <div className="container narrow">
                    <EventList events={this.eventList} type={this.state.activeEventtype} />
                </div>

                <DatePicker
                    minDate={new Date()}
                    customInput={<></>}
                    ref={this.datepickerRef}

                    withPortal
                    selected={this.state.startDate}
                    onChange={this.handleDateChange} />
            </Layout>
        )
    }
}

export default EventsPage

export const query = graphql`
    query EventsPageQuery {
        allWordpressWpEvents {
            edges {
                node {
                    id
                    wordpress_id
                    title
                    content
                    excerpt
                    slug
                    acf {
                        excerpt
                        date {
                            whole_day
                            multiple_dates
                            from
                            to
                            dates {
                                from
                                to
                            }
                        }
                        location {
                            address
                            name
                        }
                        cost
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                fixed(width: 64, height: 64) {
                                    ...GatsbyImageSharpFixed_withWebp
                                }
                            }
                        }
                    }
                    eventtypes
                    eventtype_nodes {
                        name
                    }
                }
            }
        }
    }
`
